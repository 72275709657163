import React, { PropsWithChildren } from "react";
import * as styles from "./styles.module.scss";
import { flex, centerItems, gapLg } from "styles/_flex.module.scss";
import { classnames } from "utils";
import UnorderedList from "components/unorderedList";
import * as margin from "styles/_margin.module.scss";
import logo from "images/clip-tok-logo-v2.png";
import { Link } from "gatsby";
import navItems from "../../static/JSON/navData.json";
interface LayoutTypes extends PropsWithChildren {
  className?: string;
}

interface NavProps extends PropsWithChildren {
  className?: string;
}

function Layout({ children }: LayoutTypes) {
  return (
    <div>
      <Nav>
        <Link
          to="/"
          style={{
            display: "flex",
            alignItems: "center",
            color: "#2b2b2b",
            textDecoration: "none"
          }}
        >
          <img src={logo} width={32} className={margin.right_xs} />
          <h4>lip TOK</h4>
        </Link>
        <UnorderedList
          plain
          className={classnames(flex, margin.left_auto, gapLg)}
        >
          <UnorderedList.ListItem>
            <Link to="/" style={{ color: "#2b2b2b", textDecoration: "none" }}>
              home
            </Link>
          </UnorderedList.ListItem>
          <UnorderedList.ListItem>
            <Link
              to="/#how-to"
              style={{ color: "#2b2b2b", textDecoration: "none" }}
            >
              how to
            </Link>
          </UnorderedList.ListItem>
          <UnorderedList.ListItem>
            <Link
              to="/terms-of-service"
              style={{ color: "#2b2b2b", textDecoration: "none" }}
            >
              terms of service
            </Link>
          </UnorderedList.ListItem>
          <UnorderedList.ListItem>
            <Link
              to="/privacy-policy"
              style={{ color: "#2b2b2b", textDecoration: "none" }}
            >
              privacy policy
            </Link>
          </UnorderedList.ListItem>
        </UnorderedList>
      </Nav>
      <div style={{ maxWidth: "120rem", marginInline: "auto" }}>{children}</div>
      <Footer />
    </div>
  );
}

function Nav({ children, className }: NavProps) {
  return (
    <div className={classnames(styles.navWrapper, className)}>
      <nav className={classnames(flex, centerItems)}>{children}</nav>
    </div>
  );
}

function Footer() {
  return (
    <footer className={styles.footer}>
      <h3 className={styles.heading}>Links</h3>
      <UnorderedList plain>
        {navItems.map((item) => (
          <UnorderedList.ListItem>
            <Link className={styles.footerLinkItem} to={item.to}>
              {item.text}
            </Link>
          </UnorderedList.ListItem>
        ))}
      </UnorderedList>
    </footer>
  );
}

export default Layout;
