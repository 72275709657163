import { ReactNode, DetailedHTMLProps, LiHTMLAttributes } from "react";

export const defaultProps = {
  plain: false,
  children: [],
  className: "",
  tag: "ul"
};

export interface ListProps extends ListItemProps {
  plain: boolean;
  className: string;
  children: ReactNode;
  tag: ReactNode | null;
}

export type ListItemProps = DetailedHTMLProps<
  LiHTMLAttributes<HTMLLIElement>,
  HTMLLIElement
>;
