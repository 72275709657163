// extracted by mini-css-extract-plugin
export var block = "_utils-module--block--42f5d";
export var capitalize = "_utils-module--capitalize--f27e5";
export var hidden = "_utils-module--hidden--b279e";
export var mobileHidden = "_utils-module--mobileHidden--2a9f2";
export var plainList = "_utils-module--plainList--cd4ed";
export var tabletHidden = "_utils-module--tabletHidden--aadb2";
export var textCenter = "_utils-module--textCenter--8569a";
export var textLeft = "_utils-module--textLeft--25952";
export var textOverflow = "_utils-module--textOverflow--d2f92";
export var textRight = "_utils-module--textRight--1c4d4";
export var width100 = "_utils-module--width100--9ee59";