// extracted by mini-css-extract-plugin
export var all0 = "_margin-module--all0--b4955";
export var bottom_auto = "_margin-module--bottom_auto--30023";
export var bottom_lg = "_margin-module--bottom_lg--cc8c7";
export var bottom_md = "_margin-module--bottom_md--a6f55";
export var bottom_sm = "_margin-module--bottom_sm--9d98a";
export var bottom_xl = "_margin-module--bottom_xl--cbddb";
export var bottom_xs = "_margin-module--bottom_xs--94f07";
export var left_auto = "_margin-module--left_auto--bd834";
export var left_lg = "_margin-module--left_lg--a7d55";
export var left_md = "_margin-module--left_md--fb918";
export var left_sm = "_margin-module--left_sm--05225";
export var right_auto = "_margin-module--right_auto--025f3";
export var right_lg = "_margin-module--right_lg--0ca8d";
export var right_md = "_margin-module--right_md--a82c4";
export var right_sm = "_margin-module--right_sm--ecc12";
export var right_xs = "_margin-module--right_xs--8ddd1";
export var sides_auto = "_margin-module--sides_auto--b3b02";
export var top_auto = "_margin-module--top_auto--c77dc";
export var top_lg = "_margin-module--top_lg--77291";
export var top_md = "_margin-module--top_md--52f88";
export var top_sm = "_margin-module--top_sm--d5e3b";
export var top_xs = "_margin-module--top_xs--d3333";