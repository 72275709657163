import React from "react";
import { classnames } from "utils";
import * as styles from "./styles.module.scss";
import { plainList } from "styles/_utils.module.scss";
import { ListProps, defaultProps, ListItemProps } from "./types";

function List({ tag = "ul", plain, children, className, ...rest }: ListProps) {
  const Tag = tag;
  return (
    <Tag
      className={classnames(className, styles.list, {
        [plainList]: plain,
        [styles.disc]: !plain && tag === "ul"
      })}
      {...rest}
    >
      {children}
    </Tag>
  );
}

function ListItem(
  props: React.DetailedHTMLProps<
    React.LiHTMLAttributes<HTMLLIElement>,
    HTMLLIElement
  >
) {
  return <li className={styles.listItem} {...props} />;
}

List.ListItem = ListItem;
List.defaultProps = defaultProps;

export { ListItem };
export default List;
