export function classnames() {
  let stringArray = [];
  [...arguments].forEach((arg) => {
    switch (typeof arg) {
      case "object":
        let sanitized = Object.entries(arg).map(([key, value]) => {
          switch (typeof value) {
            case "string":
            case "boolean":
              return value ? key : "";
            case "number":
              if (value > 0) {
                return key;
              }
              return null;
            default:
              return null;
          }
        });
        stringArray = [...stringArray, ...sanitized];
        break;
      case "string":
        stringArray = [...stringArray, arg];
        break;
      default:
        break;
    }
  });

  return stringArray.filter((i) => i).join(" ");
}

export function styles() {
  return Object.assign({}, ...arguments);
}

export function generateRandomString(length = 6) {
  return Math.random().toString(20).substr(2, length);
}

export function scrollToTopSmooth() {
  if (typeof window !== "undefined") {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}

export function capitalize(str) {
  if (!str || typeof str !== "string") return str;
  const separated = str.split(" ");
  return separated
    .map?.((word) => word?.[0]?.toUpperCase() + word?.slice?.(1))
    ?.join(" ");
}

export function validateArray(arr) {
  if (!arr.every((i) => i)) {
    return arr.filter((i) => i);
  }

  return arr;
}
